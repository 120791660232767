import { motion } from 'framer-motion';
import styled from 'styled-components';

import { IScProviderHero } from '../ProviderCycling/styled';

export const ScProviderNurseryHeroContainer = styled(motion.div)`
	height: calc(100svh - 85px);
	img {
		height: auto;
		width: 100%;
		max-height: 530px;
	}
	@media (min-width: 768px) {
		height: calc(100svh - 105px);
		img {
			height: 90%;
			width: auto;
			max-height: 530px;
		}
	}
	@media (min-width: 1024px) {
		img {
			height: 80%;
		}
	}
	@media (min-width: 1220px) and (min-height: 600px) {
		height: calc(100vh - 140px);
	}
`;
export const ScProviderNurseryHeroWrapper = styled(motion.div)<IScProviderHero>`
	padding: 48px 45px 25px 45px;
	box-sizing: border-box;
	background: ${({ theme, color }) => color || theme.colors.marmelade};
	height: 100%;
	justify-content: space-between;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 10px;
	gap: 40px;
	width: 100%;
	@media (min-width: 768px) {
		gap: 20px;
	}
	@media (min-width: 1220px) {
		padding: 65px 0 85px 0;
		gap: 30px;
		border-radius: 30px;
		height: 100%;
	}
	@media (min-width: 1920px) {
		gap: 50px;
	}
`;

export const ScProviderNurseryHeroButtons = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;

	button {
		padding: 11px 20px;
		font-size: 12px;
		line-height: normal;
	}

	@media (min-width: 768px) {
		flex-direction: row;
		gap: 24px;

		button {
			height: fit-content;
			font: ${({ theme }) => theme.fonts.button.large};
			padding: 15px 30px;
		}
	}
`;

export const ScNurseryInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 25px;
	align-items: center;
	margin-top: 20px;
`;

export const ScNurseryInfoItemsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 8px;

	@media (min-width: 1220px) {
		gap: 20px;
		width: 100%;
	}
`;

export const ScNurseryInfoItem = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 20px;
	background: ${({ theme }) => theme.colors.toasted};
	border-radius: 10px;
	box-sizing: border-box;

	@media (min-width: 768px) {
		padding: 54px 35px;
		border-radius: 30px;

		h6 {
			font: ${({ theme }) => theme.fonts.heading.medium};
		}
		div {
			font: ${({ theme }) => theme.fonts.paragraph.large};
			width: 100%;
		}
	}

	@media (min-width: 1220px) {
		gap: 60px;
		width: 100%;
		height: 470px;

		div,
		h3 {
			max-width: 870px;
			margin: 0 auto;
		}

		h3 {
			font: ${({ theme }) => theme.fonts.heading.card};
		}

		div {
			font: ${({ theme }) => theme.fonts.paragraph.xl};
		}
	}

	@media (min-width: 1440px) {
		height: 570px;
		padding: 70px 35px;
		div,
		h3 {
			max-width: 970px;
		}
		h3 {
			font: ${({ theme }) => theme.fonts.heading.enormous};
		}
	}
	@media (min-width: 1920px) {
		h3 {
			font: ${({ theme }) => theme.fonts.heading.xl};
		}
	}
`;

export const ScNurseryHeroWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	margin: 80px 0 100px 0;

	@media (min-width: 1220px) {
		margin: 120px 0 120px 0;
		gap: 60px;
	}
	@media (min-width: 1920px) {
		margin: 160px 0 200px 0;
		gap: 60px;
	}
`;

export const ScNurseryHeroTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	max-width: 1120px;

	@media (min-width: 1220px) {
		gap: 60px;
	}
`;

export const ScBenefitsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 25px;
	align-items: center;
	margin-top: 80px;

	@media (min-width: 768px) {
		gap: 50px;
		margin-top: 100px;
	}

	@media (min-width: 1220px) {
		margin-top: 120px;
		gap: 60px;
	}

	@media (min-width: 1920px) {
		margin-top: 200px;
		gap: 100px;
	}
`;

export const ScBenefitsTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	max-width: 1130px;

	@media (min-width: 1220px) {
		gap: 60px;
	}
`;

export const ScBenefitsItemsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 8px;
	width: 100%;

	@media (min-width: 1220px) {
		h6 {
			font: ${({ theme }) => theme.fonts.heading.small};
		}
	}

	@media (min-width: 1440px) {
		gap: 20px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		margin-bottom: 20px;

		> div {
			> div {
				padding: 56px 28px;
			}
		}

		p,
		h6 {
			margin: 0;
		}

		h6 {
			font: ${({ theme }) => theme.fonts.heading.medium};
		}
	}

	@media (min-width: 1920px) {
		h6 {
			font: ${({ theme }) => theme.fonts.heading.cardMedium};
		}
	}
`;

export const ScParentsHeroWrapper = styled(motion.section)`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	margin: 60px 0;

	img {
		width: 300px;
		height: auto;
	}

	@media (min-width: 768px) {
		img {
			width: 600px;
		}
	}

	@media (min-width: 1220px) {
		margin: 120px 0 120px 0;
		gap: 80px;

		img {
			width: 970px;
		}
	}
	@media (min-width: 1220px) {
		margin: 120px 0 120px 0;
		gap: 80px;

		img {
			width: 900px;
		}
	}
	@media (min-width: 1920px) {
		margin: 200px 0 140px 0;
		gap: 130px;

		img {
			width: 970px;
		}
	}
`;

export const ScParentsHeroTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	max-width: 1000px;

	@media (min-width: 1220px) {
		gap: 54px;
	}

	@media (min-width: 1920px) {
		max-width: 1035px;
	}
`;

export const ScParentsHeroInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;

	button {
		padding: 10px 32px;
		height: 35px;
	}

	.text {
		font: ${({ theme }) => theme.fonts.button.small};
	}

	@media (min-width: 768px) {
		gap: 64px;

		p {
			max-width: 650px;
		}

		button {
			padding: 17px 50px;
			height: fit-content;
		}

		.text {
			font: ${({ theme }) => theme.fonts.button.largeThin};
		}
	}

	@media (min-width: 1920px) {
		p {
			max-width: 800px;
		}
	}
`;
