import React from 'react';
import { motion } from 'framer-motion';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInUp } from '../../animations/variants';
import { ScContainer } from '../../components/container/styled';
import { ScParagraph } from '../../components/Paragraph/styled';
import { ScTitle } from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';

import { ScNurseryHeroTextWrapper, ScNurseryHeroWrapper } from './styled';

export const NurseryHero = () => {
	const { ref, controls } = useContentAnimation();

	return (
		<motion.section initial="hidden" animate={controls} variants={animationContainer}>
			<ScContainer>
				<ScNurseryHeroWrapper ref={ref}>
					<ScNurseryHeroTextWrapper>
						<MotionDiv variants={fadeInUp}>
							<ScTitle centered>Not your average workplace nursery scheme</ScTitle>
						</MotionDiv>
						<MotionDiv variants={fadeInUp}>
							<ScParagraph centered>
								We’re a workplace nursery scheme with a difference, designed with busy nursery
								managers in mind. And with Gogeta Nursery, your nursery receives extra funding of
								12.5% of nursery fees for every parent using the scheme.
							</ScParagraph>
						</MotionDiv>
					</ScNurseryHeroTextWrapper>
				</ScNurseryHeroWrapper>
			</ScContainer>
		</motion.section>
	);
};
