import React from 'react';
import { useTheme } from 'styled-components';

import { isNotSSR } from '@utils';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInUp } from '../../animations/variants';
import image from '../../assets/images/nursery-logo.png';
import { ScContainer } from '../../components/container/styled';
import { CustomButton } from '../../components/custom-button';
import { ScTop } from '../../components/Top/styled';
import useContentAnimation from '../../hooks/use-content-animation';

import {
	ScProviderNurseryHeroButtons,
	ScProviderNurseryHeroContainer,
	ScProviderNurseryHeroWrapper,
} from './styled';

export const ProviderNurseryHero = () => {
	const theme = useTheme();
	const { ref, controls } = useContentAnimation();

	return (
		<ScTop>
			<ScProviderNurseryHeroContainer
				ref={ref}
				initial="hidden"
				animate={controls}
				variants={animationContainer}
			>
				<ScContainer>
					<ScProviderNurseryHeroWrapper variants={fadeInUp} color={theme.colors.blueberry}>
						<img src={image} alt="bike" />
						<MotionDiv variants={fadeInUp} className="buttons">
							<ScProviderNurseryHeroButtons>
								<CustomButton
									color={theme.colors.darkText}
									background={theme.colors.wheat}
									onClick={() => {
										if (isNotSSR) {
											window.location.href = `${process.env.GATSBY_NURSERY_WEBSITE}/portal/nursery_portal/register`;
										}
									}}
								>
									Nursery partner sign up
								</CustomButton>
							</ScProviderNurseryHeroButtons>
						</MotionDiv>
					</ScProviderNurseryHeroWrapper>
				</ScContainer>
			</ScProviderNurseryHeroContainer>
		</ScTop>
	);
};
