import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from 'styled-components';

import { MotionDiv } from '../../animations/components';
import { animationContainer, cardUp, fadeInUp } from '../../animations/variants';
import { ScContainer } from '../../components/container/styled';
import { ScParagraph } from '../../components/Paragraph/styled';
import { ITextCardItem, TextCard } from '../../components/TextCard';
import { ScTitle } from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';

import { ScBenefitsItemsWrapper, ScBenefitsTextWrapper, ScBenefitsWrapper } from './styled';

const items: ITextCardItem[] = [
	{
		smallTitle: 'No extra admin',
		largeTitle: 'No extra<br/>admin<br/>&#160;',
		XlTitle: 'No extra<br/>admin',
		text: 'We know how busy you are. We slot into your existing invoicing schedule and processes, nothing needs to change.',
	},
	{
		smallTitle: 'Extra funding where you need it most',
		largeTitle: 'Extra funding<br/> where you need<br/> it most',
		XlTitle: 'Extra funding where<br/>  you need it most',
		text: 'You know your nursery better than anyone. Choose what to spend the additional funding contribution from employers on, based on your nursery’s needs and priorities.',
	},
	{
		smallTitle: 'Simplifying compliance',
		largeTitle: 'Simplifying<br/>compliance<br/>&#160;',
		XlTitle: 'Simplifying<br/>compliance',
		text: 'As part of HMRC compliance for the workplace nursery scheme, there needs to be two (virtual) meetings per year with the parent to discuss how the additional funding is spent, we handle all organisation and admin related to these meetings.',
	},
];

export const Benefits = () => {
	const { ref, controls } = useContentAnimation();
	const theme = useTheme();

	return (
		<ScContainer>
			<motion.section initial="hidden" animate={controls} variants={animationContainer}>
				<ScBenefitsWrapper ref={ref}>
					<ScBenefitsTextWrapper>
						<MotionDiv variants={fadeInUp}>
							<ScTitle centered>
								What makes
								<br /> Gogeta different?
							</ScTitle>
						</MotionDiv>
						<MotionDiv variants={fadeInUp}>
							<ScParagraph centered>
								We’re a workplace nursery scheme but the similarities to other providers ends there.
								Here’s our three promises to you:
							</ScParagraph>
						</MotionDiv>
					</ScBenefitsTextWrapper>
					<ScBenefitsItemsWrapper>
						{items.map((item) => (
							<MotionDiv key={item.smallTitle} variants={cardUp}>
								<TextCard
									item={item}
									breakpoint={1440}
									background={theme.colors.dough}
									color={theme.colors.darkText}
								/>
							</MotionDiv>
						))}
					</ScBenefitsItemsWrapper>
				</ScBenefitsWrapper>
			</motion.section>
		</ScContainer>
	);
};
