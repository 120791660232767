import React from 'react';
import { Link } from 'gatsby';
import { useTheme } from 'styled-components';

import { MotionDiv, MotionImage } from '../../animations/components';
import { animationContainer, fadeInRight, fadeInUp } from '../../animations/variants';
import image from '../../assets/images/parents-nursery-hero.png';
import { ScContainer } from '../../components/container/styled';
import { CustomButton } from '../../components/custom-button';
import { ScParagraph } from '../../components/Paragraph/styled';
import { ScTitle } from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';

import { ScParentsHeroInfoWrapper, ScParentsHeroTextWrapper, ScParentsHeroWrapper } from './styled';

export const ParentsHero = () => {
	const theme = useTheme();
	const { ref, controls } = useContentAnimation();

	return (
		<ScContainer>
			<ScParentsHeroWrapper initial="hidden" animate={controls} variants={animationContainer}>
				<MotionImage src={image} alt="employee bike hero" variants={fadeInRight} />
				<ScParentsHeroTextWrapper>
					<MotionDiv variants={fadeInUp}>
						<ScTitle centered>Tell your parents about Gogeta Nursery</ScTitle>
					</MotionDiv>
					<MotionDiv variants={fadeInUp}>
						<ScParentsHeroInfoWrapper>
							<ScParagraph centered>
								<p ref={ref}>
									Let parents know about Gogeta Nursery so you can get as much additional funding as
									possible.
								</p>
							</ScParagraph>
							<Link to="/tell-parents/">
								<CustomButton>
									<ScParagraph color={theme.colors.strongWhite} className="text">
										Find out how
									</ScParagraph>
								</CustomButton>
							</Link>
						</ScParentsHeroInfoWrapper>
					</MotionDiv>
				</ScParentsHeroTextWrapper>
			</ScParentsHeroWrapper>
		</ScContainer>
	);
};
