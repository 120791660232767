import React from 'react';

import { useMatchMedia } from '../../hooks/use-match-media';

import { ScTextCardItem, ScTextCardItemTextWrapper } from './styled';

export interface ITextCardItem {
	smallTitle: string;
	largeTitle: string;
	text: string;
	XlTitle?: string;
}

interface IImageTextCard {
	item: ITextCardItem;
	breakpoint: number;
	background?: string;
	color?: string;
}

export const TextCard = ({ item, background, color, breakpoint }: IImageTextCard) => {
	const isDesktop = useMatchMedia(`(min-width: 1676px)`);
	const isCustomBreakpoint = useMatchMedia(`(min-width: ${breakpoint}px)`);

	return (
		<ScTextCardItem background={background}>
			<ScTextCardItemTextWrapper color={color}>
				<h6
					dangerouslySetInnerHTML={{
						__html:
							item.XlTitle && isDesktop
								? item.XlTitle
								: isCustomBreakpoint
								? item.largeTitle
								: item.smallTitle,
					}}
				/>
				<p dangerouslySetInnerHTML={{ __html: item.text }} />
			</ScTextCardItemTextWrapper>
		</ScTextCardItem>
	);
};
